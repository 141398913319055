.page-item{

	.pb1{
		padding-bottom: 1em;
	}

	position: relative;

	img{
		width: 100%;
		object-fit: cover;
		position: relative;
		z-index: -1;
	}

	.content{
		position: absolute;
		bottom: 0;
		right: 0;
		width: 40%;
	    background: $grey;
	    color: $white;
	    padding: 16px;
	    margin-right: 5%;
	}

	.embed-video iframe{
		width: 100% !important;
	}

	.image-gallery{
		width:100%;
	    height: 150px;
	    object-fit: cover;
	}

	.col-4-img{
		padding: 0px 3px 6px 3px;
	}

	.col-4-img:nth-child(4n+1){
		padding-left: 15px;
	}
	.col-4-img:nth-child(4n+0){
		padding-right: 15px;
	}

}

@media (min-width: 425px){

}

@media (min-width: 768px){

}

@media (min-width: 1024px){

}

@media (min-width: 1280px){

}