#footer-sites{

	padding: 0em 0em 1em 0em;
	position: relative;
	background: #1f2326;
	font-size: 1rem;
	color: $main;
	.header{
		font-size: 1.2em;
		text-transform: uppercase;
	}

	a{
		color: $white;
	}
}

#footer{

	padding: 1.5em 0em;
	position: relative;
	background: $main-light;
	font-size: 1.2rem;
	a{
		color: $white-light;
	}

	.icons img{
		padding-right: 0.5rem;
	}

	#brand{
		font-size: 0.7em;
		position: absolute;
		bottom: 5px;
		padding-right: 15px;
		right: 0;

		#created{
			color: $white;
		}
		#brand-name{
			color: #FEE801;
		}

		a{
			text-decoration: none;
		}
	}

}