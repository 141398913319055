body{
    font-family: 'Poppins', sans-serif;
}

@media (min-width: 1200px){
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1440px;
    }
}

.container {
    padding: 3em 0;
}

// do not add anything on this class 
.container-main{
    padding: 0;
}

.row {
    margin-left: 0;
    margin-right: 0;
}
.btn-primary {
    border-radius: 1px;
    background-color: $main-light;
    border-color: #000000;
}
.btn-warning {
    border-radius: 1px;
    background-color: $yellow;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    background-color: $main-light;
    border-color: $main;
    color: $white;
    outline: 0;
}
.dropdown-toggle::after {
    display: none;
}

.pagination-nav{
    margin-top: 16px;
}

.page-item.active {
    .page-link {
        color: $main-light;
        background-color: $main;
        border-color: $main;
        font-weight: 700;
    }
}

.page-link {
    color: $black;
}

.mb2 {
    margin-bottom: 2em;
}

.mt2 {
    margin-top: 2em;
}

.mt1 {
    margin-top: 1em;
}

.mb1 {
    margin-bottom: 1em;
}

.w-100 {
    width: 100%;
}

a:hover,
a:focus {
    color: #3b98c8;
    text-decoration: none;
}

.form-control {
    border-radius: 0px;
}

.info-span {
    font-size: 12px;
    font-weight: 200;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: $main;
    outline: 0;
    box-shadow: 0 0 0 0.2rem $main-light;
}

.btn-main {
    color: $white;
    background: $main-light;
    // border: 2px solid $main;
    text-align: center;
    padding: 0.5em 1em;
    border-radius: 0px;
    transition: 0,8s;
}

.btn-main:disabled,
.btn-main[disabled] {
    cursor: not-allowed;
}

.btn-main:hover {
    color: $white;
    background: $main-dark;
}

.btn-gold {
    color: $black;
    text-align: center;
    padding: 0.8em 1em;
    font-weight: bold;
    border: 2px solid $main;
    border-radius: 1px;
}

.btn-grey {
    color: $white;
    background: $grey;
    text-align: center;
    padding: 0.8em 1em;
    border-radius: 0px;
}


// animation for icon loading
#animation-rotate {
    animation: rotation 1.5s infinite linear;
    position: absolute;
    left: 1em;
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #ffffff;
    border-color: #6c757d;
}

.div-logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.search-container{
    margin-top: 8px; 
    margin-bottom: 16px;
    padding-top: 0;
    padding-bottom: 0;
}

.search{
    width: 100%;
    padding: 0 16px;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

#newsletter {
    margin-bottom: 1em;

    label {
        float: left;
        margin-top: 6px;
    }

    p {
        float: left;
        width: 85%;
        text-align: justify;
        margin-left: 1em;
    }
}

.border-error {
    border-bottom: 1px solid $red;
}

/* checkbox style */

.switch {
    position: relative;
    display: inline-block;
    width: 43px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: $main;
}

input:focus + .slider {
    box-shadow: 0 0 1px $main;
}

input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.badge-cart {
    background: $main-light;
    color: white;
    position: absolute;
    top: 2px;
    right: 6px;
    z-index: 18;
    padding: 4px 6px;
}

.password-remind {
    color: $black;
    padding: 0;
    font-size: 0.8em;
}

#password-remind-blade {
    padding: 4em 0 6em 0;
}


@media (min-width: 768px){
    .search-container{
        .search{
            width: 280px;
            padding: 0 16px;
        }
    }
}

@media (min-width: 1024px){	
    .search-container{
        padding: 0;
        margin-top: -82px;
        margin-bottom: 38px;    
    }
}

@media (min-width: 1280px){	

}