
.product-name{
	font-size: 0.8em;
}

.details{
	margin-top: 1em;
	padding: 1em 1em;
	background: $grey-light;
}

.user-orders{

	.order-list{
		margin-top: 1em;
		padding: 1em 1em;

		a{
			text-decoration: none;
			color: $main-dark;
		}

		.table-dark{
			color: $black;
		}
	}
}

.user-account{
	#remove{
		position: absolute;
		bottom: 16px;
	}
}

@media (min-width: 425px){

}

@media (min-width: 768px){
	.product-name{
		font-size: 1em;
	}

}

@media (min-width: 1024px){
	
}

@media (min-width: 1280px){	

}