#product{
	#thumbnail-carousel .splide__slide img {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	}


    #info {
        h1 {
            margin-left: -2px;
        }
    }

	.add-to-cart-row {
		display: flex;
		flex-direction: row;
		align-items: center;

		.qty {
			height: 100%;
			width: 15%;
			margin-right: 8px;
			padding: 0.5em 1em;
		}
	}

	#thumbnail-carousel .splide__slide {
	  margin-top: 16px;	
	  opacity: 0.6;
	  border: 1px solid #00000044;
	}

	#thumbnail-carousel .splide__slide.is-active {
	  opacity: 1;
	}

	#main-carousel .splide__list, #main-carousel .row{
		height: 370px;
	}
	#main-carousel img{
	    max-width: 100%;
	    max-height: 100%;
	    display: block;
	    margin: auto;
	}

    .price-container{
    	font-size: 1.6em;
	    .price-cat {
	        color: $grey;
	        text-decoration: line-through;
	    }

	    .price{
	        font-size: 1.2em;
	    	color: $main-dark;
	    	font-weight: bold;
	    }
    }

	#variants{
		.item{
			img {
				width: 70px;
				height: 70px;
				object-fit: cover;
			}
		}
		.col-md-12{
			padding: 0;
		}
	}
	.addons{
	    padding: 10px;
	    border: 1px solid $grey-light;
	    text-align: center;
	   	cursor: pointer;
	   	margin-right: 8px;
		
		&:hover{
			border: 1px solid $color-accent !important;
		}
	}
	.addons.addon-selected{
		border: 1px solid $color-accent !important;
	}

	#up-selling{
		margin-top: 2rem;
		#header{
			font-size: 1.2rem;
		}
		p{
			color: #000000CC;
		}
		
		.product-name-small{
			margin-top: 16px;
		    font-size: 18px;
    		font-weight: 600;
    		margin-bottom: 0;
		}
		
		#extra-info{
			font-size: 13px;
		}

	    img{
            object-fit: cover;
            margin-bottom: 16px;
        }
	}

    .nav-tabs{
    	border-bottom: 1px solid $main-dark;
    	.nav-item{
    		a{
    			border-radius: 0px;
    			color: $black;
    			
    			&.active{
    				background: $main;
    				border: 1px;
    				border-bottom: 1px solid $main-dark;
    			}
    		}
    	}
    }
    
    .tab-panel{
    	padding: 16px 8px;
    }


}



@media (min-width: 425px){

}

@media (min-width: 768px){

}

@media (min-width: 1024px){
	
}

@media (min-width: 1280px){	

}