#filters{
	background: $white;
	padding: 12px;
	box-shadow: 0px 0px 4px #00000033;
	margin-top: 25px;
}

#products-filters{	

	.filter-item, .filter-tree {
	    display: none;
	}

	.filter-item:has(.showbranch), .filter-tree:has(.showbranch) {
	    display: block !important;
	}

	ul{
		padding-left: 12px;
		margin-bottom: 0px;
		list-style-type: none;
	}
	li{
		list-style-type: none;
	}

	.main-category{
		font-weight: 300;
	}
	a{
		color: #000000CC;
		&.active {
			color: $main-dark;
			font-weight: bold;
		}
	}
}