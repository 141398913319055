.products {
    padding: 2em 0 3em 0;
    font-family: $font-family-sans-serif;
    background-color: #FAF8F6;

    // custom width of pagination items
    .page-link{
        padding: 0.5rem;
    }
    .listing{
        .product-list-item{        
            padding-right: 0px;
            padding-left: 0px;
        }
    }

    .product-list {
        cursor: pointer;
        background: $white;    
        box-shadow: 0px 0px 4px #00000033;
        transition: 0.4s;
        margin-bottom: 16px;

        &:hover{
            transform: translateY(-3px);
        }


        .images {

            img{
                height: auto;
                width: 70%;
                position: relative; 
                left: 50%;
                transform: translateX(-50%);
            }

            .new {
                position: absolute;
                background: $black;
                color: #16d36e;
                font-size: 0.7em;
                padding: 0.25em 0.7em 0.25em 2em;
                right: 14px;
            }
        }

        .lnr-pencil {
            font-size: 1.2em;
        }

        .btn {
            padding: 7px 11px !important;
        }

        .edit {
            position: absolute;
            top: 10px;
            right: 26px;
        }
        .info {
            padding: 1em 1em;

            .name {
                color: $black;
                font-size: 1.4em;
                margin-bottom: 0;
            }

            .price {
                color: $main-dark;
                font-weight: bold;
                font-size: 1.2em;
                margin-right: 0.5em;
            }

            .price-cat {
                color: $grey;
                text-decoration: line-through;
            }
        }
    }
    #qty-info{
        p{
            padding-bottom: 4px;
            border-bottom: 1px solid $main;
        }
    }
    .disabled {
        background: $grey-light;
    }
    .product-description {
        margin-top: 1.5em;
    }
}

#categories {
    display: none;
    margin-top: 1em;
}

.products-bg {
    background: $white;
}

#search {
    border: none;
    padding: 8px;
    color: white;
}

#search:hover {
    background: $color-accent;
}
.product {
    margin-top: 0.5em;

    #prod-box{
        .col-md-6{
            padding: 0.5em 0;
        }
    }

    #description {
        margin-top: 2em;
        margin-bottom: 3em;
        font-size: 0.9em;
        color: $black-dark;
        padding-left: 3px;

        p{
            margin-bottom: 1px;
        }
    }

    .info {
        padding-right: 1em;
        padding-bottom: 1em;

        .name {
            color: $black;
        }

        .price {
            color: $main-dark;
            font-weight: bold;
            font-size: 1.5em;
        }

        .price-cat {
            color: $grey;
            text-decoration: line-through;
        }
        .size {
            border-radius: 0px;
            padding: 0.8em 0 0.8em 1em;
            height: auto;
        }
    }

    .alert {
        margin-bottom: 0;
    }
}

@media (max-width: 991px) {
    .product {
        margin-top: 0.5em;

        #info {
            background: whitesmoke;
            z-index: 999;
            h1 {
                font-size: 1.3rem;
                text-align: center;
                padding-top: 10px;
            }
            .info {
                padding: 0;
                .name {
                    color: $black;
                }

                .price {
                    font-weight: bold;
                    font-size: 1.2em;
                }

                .price-cat {
                    color: $grey;
                    text-decoration: line-through;
                }
                .size {
                    border-radius: 0px;
                    padding: 0.8em 0 0.8em 1em;
                    height: auto;
                    width: 40%;
                }
                .add-to-cart-container {
                    justify-content: space-around;
                    margin-top: 6%;
                }
                .price-container {
                    width: 100%;
                    text-align: center;
                }
                .w-100 {
                    width: 46% !important;
                }
                .mt1 {
                    margin-top: 0 !important;
                }
            }
        }

        .alert {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 768px) {
    .products {
        padding: 2em 0 3em 0;

        .page-link{
            padding: 0.5rem 0.75rem;
        }

        .listing{
            .product-list-item:nth-child(3n+1){
                padding-left: 0px;
                padding-right: 6px;
            }

            .product-list-item:nth-child(3n+2){
                padding: 0 3px;
            }

            .product-list-item:nth-child(3n){        
                padding-right: 0px;
                padding-left: 6px;
            }
        }

        // single product on PRODUCTS LIST
        .product-list{
            border-top: 8px solid $white;
            border-left: 8px solid $white;
            border-right: 8px solid $white;
            border-bottom: 1px solid $grey-light;
            margin-top: 0.5em;
            margin-bottom: 0px;            

            .info {
                p {
                    margin-bottom: 0.2em;
                }
                hr {
                    margin-top: 0em;
                }
                .name {
                    font-size: 0.8em;
                    height: 24px;
                    overflow-y: hidden;
                }
            }

            #prod-box {
                .row {
                    .col-md-6:nth-child(1) {
                        padding-right: 0.5em;
                    }
                    .col-md-6:nth-child(2) {
                        padding-left: 0.5em;
                    }
                }
            }
        }

        .product-list-single-page{
            border-bottom: none;
        }


        .product-description {
            margin-top: 8em;
        }
    }
    #info{        
        h1{
            font-size: 1.8rem;
        }
    }

    #categories, #filter-price {
        display: block;
        margin-top: 0;
        hr {
            margin-top: 0em;
        }

        a {
            color: $black;
            text-decoration: none;
        }

        p {
            font-size: 1em;
            margin-bottom: 0.2em;
        }
    }

    // single product view
    .product {
        margin-top: 0.5em;
        
        #description{
            font-size: .8em;
            margin-top: 1.5em;
            margin-bottom: 1.5em;
        }

        #info{
            margin-top: 0.5em;
        }

        #prod-box {
            .row {
                .col-md-6{
                    padding: 1em 0;
                }
                
                .col-md-6:nth-child(1) {
                    padding-right: 0.5em;
                }
                .col-md-6:nth-child(2) {
                    padding-left: 0.5em;
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    #info{        
        h1{
            font-size: 2.5rem;
        }
    }
}

@media (min-width: 1280px) {
    #info{        
        h1{
            font-size: 3rem;
        }
    }

    .products {
        .product-list{
            .info {
                .name {
                    font-size: 1.2em;
                    height: 29px;
                    overflow-y: hidden;
                }
            }
        }
    }
}
