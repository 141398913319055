.navbar {
    // font-family: 'Comfortaa', cursive; same as logo
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

    .menu {
        .dropdown-item:hover, .dropdown-item:focus{
            background-color: $main;
        }

        ul{
            background-color: $main-dark !important;
            color: $white;
        }
        li {
            color: $white;

            a {
                text-transform: uppercase;
                font-weight: 500;
                color: $white !important;
            }
        }
    }    
}

@media (min-width: 767px) {
    .navbar-nav li:hover>ul.dropdown-menu {
        display: block;
        border-radius: 0px;
        margin-left: -1px;
        margin-top: 0px;
        padding: 0;
        border: none;    
    }
    .navbar {
        .menu {

            ul{
                background-color: $main !important;
            }

            li {
                color: $black-dark;

                a {
                    color: $black-dark !important;
                }
            }
        }  
    }  
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
}

/* rotate caret on hover */
// .dropdown-menu>li>a:hover:after {
//     text-decoration: underline;
//     transform: rotate(-90deg);
// }

.navbar-menu{
    min-height: 56px;
    .nav-item, .dropdown-menu>li {
        display: none;
    }

    .nav-item:has(.showbranch), .dropdown-menu>li:has(.showbranch) {
        display: block;
    }
}

#bar{
    background-color: $main-light;
    color: #FFFFFFDD;

    .container{
        padding: 2px;

        p{
            margin: 5px 0;
            font-size: 13px;
            font-weight: 500;
        }

        .divider{
            padding: 0 5px;
            font-size: 13px;
        }
    }
}

.navbar-logo {    
    justify-content: center;
    padding: 1em 0;
    .icons {
        margin-right: 1em;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }
}

.navbar-expand-sm .navbar-nav .nav-link {
    padding: 0.5em 1em;
}

.navbar-bg-main{
    background: $white;
    box-shadow: 0px 6px 6px #00000015;
}

.burger-right {
    justify-content: flex-end;
}
@media (max-width: 767px) {
    .navbar-logo {
        justify-content: flex-start;
    }
    .nav-item {
        text-align: center;
    }

    .logo{
        width: 55%;
        height: auto;
        margin-left: 16px;
    }
    .navbar-bg-main{
        background: $main-dark;
    }
    .navbar-dark .navbar-toggler{
        color: $main-light;
        border-color: $main-light;
    }
}
