#homepage{
    .news{
        background: #FAF8F6;
    }
    .products{ 
        .product-list{
            margin-bottom: 0px;
        }
        .bestseller{
            box-shadow: 0px 0px 8px #00000050;
            margin: 16px 8px;
            border: none;
        }
        h2{
            font-size: 1.4em;
            margin-bottom: 1em;
        }
    }
    #image-carousel{
        .splide__slide{
            padding: 8px;
        }
    }

    #extra-categories{
        .image{
            position: relative;
        }
        h2{
            padding: 0.5em 0;
            color: $black-dark;
            position: absolute;
            bottom: -50px;
            left: 0;
            background: #f2f2f2;
            width: 100%;
            text-align: center;
        }
    }

}

#sales{
    margin-top: 24px;
    background: #222;
    font-size: 18px;
    .container{
        padding: 1em 0;
    }
     a, p{
        color: #FFF;
        margin-bottom: 0;
     }
    .gold{
        font-size: 24px;
        color: $color-accent !important;
    }

    .btn-white{
        background: #fff;
        color: #000;
        font-size: 16px;
        font-weight: 600;
        border-radius: 0px;
        padding: 4px 6px;
        margin-top: 4px;
        // width: 100%;
    }
}

#bestsellers{
    background: $main;
    .products {
        background: $main;
        .product-list{
            border: none;
            padding: 0.5em;
        }
    }
}

#shop-info{
    padding: 2em 0;
    p{
        font-size: 18px;
    }

    img{
        height: 55px;
    }
    #delivery{
        height: 65px;
    }
}

@media (min-width: 768px) {
    #homepage{
        .products{ 
            h2{
                font-size: 2em;
            }
        }
    }

    #sales{
        font-size: 27px;
        color: $color-accent;
        .gold{
            font-size: 32px;
        }

        .btn-white{
            width: auto;
            font-size: 21px;
            padding: 8px 18px;
            margin-top: 8px;
        }
    } 
}

@media (min-width: 1024px) {
    #sales{
        .btn-white{
            font-size: 24px;
            margin-top: 8px;
        }
    } 
}