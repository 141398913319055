.reload {
    opacity: 0.6;
}
.click-item {
    cursor: pointer;
}

.disabled-item {
    svg {
        fill: $grey;
    }
}

.cart-product{
    font-size: 0.9em;
    margin-top: 0;
}

.cart {
    min-height: 350px;
    padding: 4em 0 6em 0;

    .image {
        padding: 0.5em 0;
    }

    .cart-image{
        width: 85%;
    }

    a {
        color: $black;
    }

    .qty {
        padding: 0 0.4em 0 0.3em;
    }
}

.checkout {
    padding: 4em 0 6em 0;

    .card {
        border: none;
    }
    .card-link {
        color: $black;
    }

    .card-body {
        padding: 0 0;
    }

    .table th,
    .table td {
        padding: 0;
    }

    .card-header {
        background-color: #dfe6e955;
        border-radius: 0px;
        border-bottom: 3px solid $main;
        svg {
            fill: #525252;
            margin-top: -4px;
        }
        span {
            font-size: 1.5em;
            margin-left: 1em;
        }
    }

    label {
        width: 100%;
        margin-bottom: 0;
    }
    .radiotext {
        cursor: pointer;
        margin: 10px 0px 10px 0px;
        
    }
    .radiotext.select-option{
        text-align: center;
    }

    #order-list {
        margin: 1em 0 1em 0;
        cursor: pointer;
    }

    #add-promocode {
        margin: 1em 0 1em 0;
        cursor: pointer;
    }

    #order {
        .row:not(:last-child) {
            padding-bottom: 1em;
            border-bottom: 1px solid $grey-light;
        }
    }

    .small-text{
        font-size: 12px;
        a{
            color: $main-dark;
        }
    }

    #delivery-calendar{
        margin-bottom: 16px;
    }
}
.payment {
    .item {
        cursor: pointer;
    }
}

.cart-item {
    margin-bottom: 1em;

    .product-name{
        font-size: 16px;
    }
}
.dropdown-cart {
    min-width: 250px;
    border-radius: 1px;
    padding: 1em;
    margin-right: 1em;
    a {
        color: $black;
    }
    h4 {
        margin-bottom: 0px;
    }
}
.dropdown-item.active,
.dropdown-item:active {
    background-color: $main;
}

.dropdown-cart-user {
    border-radius: 1px;
    padding: 1em;
    margin-right: 1em;
    right: 3.5em;
    a {
        color: $black;
    }
}

#cart-total {
    margin-bottom: 0.8rem;
}

.summary {
    padding: 4em 0 6em 0;
}

.dropdown-menu .empty-cart-text {
    font-size: 12px;
}

@media (min-width: 768px) {
    .cart-product{
        font-size: 1.4em;
    }
}

@media (min-width: 1024px) {
    .dropdown-cart {
        min-width: 380px;
    }
    .dropdown-menu .empty-cart-text {
        font-size: 24px;
    }
}

.table-dark, .table-dark > td{
    color: black;
    background-color: #ebebeb !important;
}